import React, { FunctionComponent as FC, useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { navigate, useLocation } from '@reach/router';
import * as queryString from 'query-string';
import { auth } from '../firebase';
import { applyActionCode } from 'firebase/auth';
import Alerts from '../components/alerts';
import AuthLayout from '../components/auth-layout';
import { useTranslation } from '../hooks/useTranslation';
import Button from '../components/buttons';
import useAuth from '../hooks/useAuth';
import routes from '../routes';
import config from '../config';

type QueryParams = {
  mode: string;
  oobCode: string;
  continueUrl: string;
  lang: string;
  tenantId?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    continueButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '14px',
    },
  }),
);

//Custom handler for firebase verifications.
//https://firebase.google.com/docs/auth/custom-email-handler#web-namespaced-api_3
export const UserManagement: FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const { isAuthenticated, signOut } = useAuth();

  const queryParams = queryString.parse(location.search) as QueryParams;
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { mode, oobCode, continueUrl, tenantId } = queryParams;

  useEffect(() => {
    switch (mode) {
      case 'verifyEmail':
        void applyModeOperation(
          t('FIREBASE_CUSTOM_HANDLER_EMAIL_VERIFIED'),
          t('FIREBASE_CUSTOM_HANDLER_EMAIL_VERIFY_ERROR'),
        );
        break;
      case 'revertSecondFactorAddition':
        void applyModeOperation(
          t('FIREBASE_CUSTOM_HANDLER_SECOND_FACTOR_REVERTED'),
          t('FIREBASE_CUSTOM_HANDLER_SECOND_FACTOR_REVERT_ERROR'),
        );
        break;
      case 'signIn':
        handleSignIn();
        break;
      case 'resetPassword':
        handleResetPassword();
        break;
      default:
        void handleNotSupported();
        break;
    }
  }, []);

  const handleSignIn = () => {
    if (tenantId === config.firebase.installerTenentId) {
      void navigate(routes.PGE_PLUS_INSTALLER_PORTAL_SIGN_IN + location.search);
    }
  };

  const handleResetPassword = () => {
    if (tenantId === config.firebase.installerTenentId) {
      void navigate(
        routes.PGE_PLUS_INSTALLER_PORTAL_FORGOT_PASSWORD + location.search,
      );
    }
  };

  const applyModeOperation = async (successMsg: string, errorMsg: string) => {
    try {
      await applyActionCode(auth, oobCode);
      setSuccess(successMsg);

      //Force users to login after verifying the email
      if (isAuthenticated) {
        setTimeout(() => {
          void signOut(continueUrl ? continueUrl : routes.ACCOUNT);
        }, 3000);
      }
    } catch (e) {
      setError(errorMsg);
    }
  };

  const handleNotSupported = () => {
    setError(t('FIREBASE_CUSTOM_HANDLER_UNSUPPORTED_MODE'));
  };

  return (
    <AuthLayout title={''} footer={<></>} showLeft={false}>
      {success && (
        <Grid container>
          <Grid item xs={12}>
            <Alerts
              severity={'success'}
              variant={'outlined'}
              title={t('SUCCESS')}
              message={<Typography component="div">{success}</Typography>}
              isOpen
            />
          </Grid>
          <Grid item xs={12} className={classes.continueButtonContainer}>
            {continueUrl && !isAuthenticated && (
              <Button onClick={() => navigate(continueUrl)}>
                {t('CONTINUE')}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      {error && (
        <Alerts
          severity={'error'}
          variant={'outlined'}
          title={t('ERROR')}
          message={<Typography component="div">{error}</Typography>}
          isOpen
        />
      )}
    </AuthLayout>
  );
};

export default UserManagement;
